import React from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { parse, isDate } from 'date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import styled from 'styled-components'

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  color: #1f2447;
  font-family: 'GTAmerica', Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  max-width: 245px;

  .MuiInput-formControl,
  .MuiInputBase-root {
    background-color: #fff;
  }

  .MuiFormHelperText-root {
    color: #fff !important;
  }

  input {
    background: #fff;
    margin: 0;
    padding: 1.1875rem 0 1.1875rem 1rem !important;
  }

  svg {
    fill: #4155cb !important;
  }
`

const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault()
    e.stopPropagation()
  }
}

const DatePickerField = ({ id, name, ...additionalDatePickerProps }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <StyledKeyboardDatePicker
      id={id}
      name={name}
      autoOk
      disableFuture
      disableToolbar
      format="MM/dd/yyyy"
      inputProps={{ inputMode: 'numeric' }}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      onKeyDown={handleKeyDown}
      placeholder="mm/dd/yyyy"
      PopoverProps={{ style: { left: '26px' } }}
      variant="inline"
      {...additionalDatePickerProps}
    />
  </MuiPickersUtilsProvider>
)

DatePickerField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

const parseDateString = (_, originalValue) =>
  isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'MM/dd/yyyy', new Date())

export { DatePickerField, parseDateString }
