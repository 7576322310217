import React, { useState, useEffect, useRef } from 'react'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import classNames from 'classnames'
import obStyles from '../layout-onboarding.module.scss'
import '../layout-onboarding.scss'

import { getBreedAll, getBreedSuggest } from '../../services/zootopia'
import AutocompleteField from './Autocomplete'

const OPTION_I_DONT_KNOW = "I don't know"
const DEFAULT_BREED_VALUE = { _source: { wisdomId: -1, name: '' } }
const DEFAULT_BREED_LABEL = 'Start typing a breed name'
const DEFAULT_BREEDS = [
  DEFAULT_BREED_VALUE,
  { _source: { wisdomId: 0, name: OPTION_I_DONT_KNOW } },
]

const breedsFromStorage = () =>
  [0, 1, 2, 3]
    .map((elem) => {
      const name = localStorage.getItem(`onboarding_breed_${elem}`)
      const wisdomId = localStorage.getItem(`onboarding_breedId_${elem}`)

      if (name && wisdomId) {
        // If a breed is found in one of the `breed slots` in local storage, return an
        // option for that breed
        return { _source: { wisdomId, name } }
      }
    })
    .filter((elem) => !!elem)

const clearBreedsFromStorage = () =>
  [0, 1, 2, 3].forEach((elem) => {
    localStorage.removeItem(`onboarding_breed_${elem}`)
    localStorage.removeItem(`onboarding_breedId_${elem}`)
  })

const breedsToStorage = (selectedBreeds) => {
  clearBreedsFromStorage()

  selectedBreeds.forEach((elem, i) => {
    localStorage.setItem(`onboarding_breed_${i}`, elem._source.name)
    localStorage.setItem(`onboarding_breedId_${i}`, elem._source.wisdomId)
  })
}

export const Breed = ({ className, formData, navigation }) => {
  const [selectedBreeds, setSelectedBreeds] = useState(breedsFromStorage())
  const [breedOptions, setBreedOptions] = useState(DEFAULT_BREEDS)
  const [breedLabel, setBreedLabel] = useState(DEFAULT_BREED_LABEL)

  const [value, setValue] = useState(breedOptions[0])
  const [inputValue, setInputValue] = useState('')

  const previousVals = useRef({ value, inputValue })

  useEffect(() => {
    const fn = async () => {
      const allBreeds = await getBreedAll()

      setBreedOptions([...DEFAULT_BREEDS, ...allBreeds])
    }

    fn()
  }, [])
  useEffect(() => {
    if (selectedBreeds.length >= 1) {
      setBreedLabel(
        `+ Add another breed if ${gender === 'boy' ? 'he' : 'she'} is a mix`
      )
    } else {
      setBreedLabel(DEFAULT_BREED_LABEL)
    }
  }, [selectedBreeds])

  useEffect(() => {
    const func = async () => {
      // Have both values changed?
      if (
        previousVals.current.value !== value &&
        previousVals.current.inputValue !== inputValue
      ) {
        // Update previousVals
        previousVals.current = { value, inputValue }

        const name = value && value._source && value._source.name

        // !!name only executes the first condition if name is not ``, etc.
        if (!!name && name.toLowerCase().includes(inputValue.toLowerCase())) {
          // A breed was selected
          addSelection(value)
          clearBreedDropdown()
        } else {
          // The breed selection dropdown was cleared - name is null and inputValue is `` so the
          // following condition is true
          if (!name && !inputValue) {
            clearBreedDropdown()
          }
        }
      }
      // TODO: I'm leaving this in here until after the initial launch
      // else {
      //   if (inputValue !== OPTION_I_DONT_KNOW && inputValue.length >= 3) {
      //     // If inputValue !== `I don't know` and is longer than 3 characters and isn't equal to name (which
      //     // indicates that the user has made a selection)... Fetch the breed suggestions for inputValue
      //     // and update the breed dropdown's options
      //     const suggestions = await getSuggest(inputValue)

      //     setBreedOptions(suggestions)
      //   }
      // }
    }

    func()
  }, [value, inputValue])

  const clearBreedDropdown = () => {
    setInputValue('')
    setValue(DEFAULT_BREED_VALUE)

    // TODO: I'm leaving this in here until after the initial launch
    // setBreedOptions(DEFAULT_BREEDS)
  }

  const addSelection = (selection) => {
    // Note: Multiple `I don't know` selections are allowed so there's no code that checks whether the
    //       `I don't know` `breed` has already been selected and that removes it from the subsequent available
    //       options if it has
    const breedName = selection._source.name

    if (
      breedName === OPTION_I_DONT_KNOW ||
      !selectedBreeds.some((elem) => elem._source.name === breedName)
    ) {
      setSelectedBreeds(selectedBreeds.concat(selection))
    }
  }

  const handleRemove = (index) => {
    removeBreeds(formData)

    const newBreeds = selectedBreeds.filter((_, i) => i !== index)

    addBreeds(newBreeds, formData)

    setSelectedBreeds(newBreeds)
  }

  const removeBreeds = (data) =>
    [0, 1, 2, 3].forEach((slot) => {
      delete data[`breedId_${slot}`]
      delete data[`breed_${slot}`]
    })

  const addBreeds = (breeds, data) => {
    breeds.forEach((breed, i) => {
      const source = breed._source

      data[`breedId_${i}`] = source.wisdomId
      data[`breed_${i}`] = source.name
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    breedsToStorage(selectedBreeds)
    breedsDataLayer(selectedBreeds)
    navigation.next()
  }

  const breedsDataLayer = () => {
    if (
      selectedBreeds.length === 0 ||
      (selectedBreeds.length === 1 &&
        selectedBreeds[0]._source.name === "I don't know")
    ) {
      window.dataLayer.push({
        event: 'onboarding',
        breedType: 'unknown',
        breed1: 'none',
        breed2: 'none',
        breed3: 'none',
        breed4: 'none',
      })
    } else if (selectedBreeds.length === 1) {
      window.dataLayer.push({
        event: 'onboarding',
        breedType: 'purebred',
        breed1: selectedBreeds[0]._source.name,
        breed2: 'none',
        breed3: 'none',
        breed4: 'none',
      })
    } else if (selectedBreeds.length === 2) {
      window.dataLayer.push({
        event: 'onboarding',
        breedType: 'mix',
        breed1: selectedBreeds[0]._source.name,
        breed2: selectedBreeds[1]._source.name,
        breed3: 'none',
        breed4: 'none',
      })
    } else if (selectedBreeds.length === 3) {
      window.dataLayer.push({
        event: 'onboarding',
        breedType: 'mix',
        breed1: selectedBreeds[0]._source.name,
        breed2: selectedBreeds[1]._source.name,
        breed3: selectedBreeds[2]._source.name,
        breed4: 'none',
      })
    } else {
      window.dataLayer.push({
        event: 'onboarding',
        breedType: 'mix',
        breed1: selectedBreeds[0]._source.name,
        breed2: selectedBreeds[1]._source.name,
        breed3: selectedBreeds[2]._source.name,
        breed4: selectedBreeds[3]._source.name,
      })
    }
  }

  const { name, gender } = formData

  const getSuggest = async (nameSuggest, idx = 0) => {
    if (
      typeof nameSuggest === 'undefined' ||
      !nameSuggest ||
      nameSuggest.length < 3
    ) {
      setBreedList[idx](
        idx === 0
          ? [{ label: OPTION_I_DONT_KNOW, value: 0 }]
          : [{ label: ' ', value: 0 }]
      )

      return breedList[idx] || []
    }

    return await getBreedSuggest(nameSuggest)
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step05/breed',
      title: 'Onboarding - Breed',
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>What's {name}'s breed?</h1>
      <form className={obStyles.onboardingForm} onSubmit={handleSubmit}>
        <div className={obStyles.formBody}>
          <label className={obStyles.label} htmlFor="combo-box-breed">
            Breed
          </label>
          {!!selectedBreeds.length &&
            selectedBreeds.map((elem, i) => (
              <div className={obStyles.breed} key={`breed-${i}`}>
                <span>{elem._source.name}</span>
                <HighlightOffIcon
                  className={obStyles.remove}
                  onClick={() => handleRemove(i)}
                />
              </div>
            ))}
          {selectedBreeds.length < 4 ? (
            <AutocompleteField
              id="combo-box-breed"
              name="breed"
              options={breedOptions}
              getOptionLabel={(option) => option._source.name}
              onChange={(_, newVal) => {
                setValue(newVal)
                // While this fixes the issue of not passing the correct data to the next onboarding step,
                // mutating state like this is incorrect, non-idiomatic and risky
                //
                // TODO: Improve/replace this functionality
                formData[`breedId_${selectedBreeds.length}`] = newVal
                  ? newVal._source.wisdomId
                  : 0
              }}
              onInputChange={(_, newVal) => {
                setInputValue(newVal)
                formData[`breed_${selectedBreeds.length}`] = newVal
              }}
              value={value}
              inputValue={inputValue}
              textFieldLabel={breedLabel}
              className={classNames({
                addAnother: breedLabel !== DEFAULT_BREED_LABEL,
              })}
            />
          ) : null}

          {!selectedBreeds.length ? (
            <div id="selectNote" className={obStyles.selectNote}>
              + Add another breed if {gender === 'boy' ? 'he' : 'she'} is a mix
            </div>
          ) : null}
        </div>

        <div className={obStyles.navigation}>
          <div>
            <button
              className={obStyles.back}
              onClick={() => navigation.previous()}
            >
              Back
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={obStyles.button}
              disabled={!selectedBreeds.length}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
