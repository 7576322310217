import React from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'

import obStyles from '../layout-onboarding.module.scss'

export const BuildingCustomHomepage = ({ className, formData, navigation }) => {
  const { breed_0, breed_1, breed_2, breed_3, firstDog, size } = formData
  const breeds = [breed_0, breed_1, breed_2, breed_3].filter(
    (breed) => breed && breed !== "I don't know"
  )

  setTimeout(() => {
    navigation.next()
  }, 6000)

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>Building your custom homepage...</h1>
      <p className={obStyles.desc}>
        We're digging up great content that's just for you.
      </p>
      <div className={obStyles.homepageItems}>
        <ul>
          <li className={obStyles.breeds}>
            <FiberManualRecordIcon />
            <p>{breeds.length === 1 ? breeds[0] : 'Supermix'}</p>
          </li>
          <li className={obStyles.petParent}>
            <FiberManualRecordIcon />
            <p>
              {firstDog === 'yes' ? 'First time ' : 'Experienced '}Dog Parent
            </p>
          </li>
          <li className={obStyles.petSize}>
            <FiberManualRecordIcon />
            <p>{size} Size Dog</p>
          </li>
        </ul>
      </div>
    </div>
  )
}
