import React from 'react'
import { useForm, useStep } from 'react-hooks-helper'
import { navigate } from 'gatsby'
import classNames from 'classnames'

import { Hello } from '../components/onboarding/Hello'
import { Name } from '../components/onboarding/Name'
import { NameConfirmation } from '../components/onboarding/NameConfirmation'
import { BirthDate } from '../components/onboarding/BirthDate'
import { Gotchaversary } from '../components/onboarding/Gotchaversary'
import { Size } from '../components/onboarding/Size'
import { ZipCode } from '../components/onboarding/ZipCode'
import { Breed } from '../components/onboarding/Breed'
import { BreedConfirmation } from '../components/onboarding/BreedConfirmation'
import { FirstDog } from '../components/onboarding/FirstDog'
import { BuildingCustomHomepage } from '../components/onboarding/BuildingCustomHomepage'
import { Signup } from '../components/onboarding/Signup'

import LayOnboarding from '../components/layout-onboarding'
import { animateTransition } from '../components/layout-onboarding.module.scss'

import { startOnboarding } from '../services/zootopia'

import { isLoggedIn } from '../services/auth'

const steps = [
  { id: 'hello' },
  { id: 'name' },
  { id: 'nameConfirmation' },
  { id: 'birthDate' },
  { id: 'gotchaversary' },
  { id: 'breed' },
  { id: 'breedConfirmation' },
  { id: 'size' },
  { id: 'stepZip' },
  { id: 'firstDog' },
  { id: 'buildingCustomHomepage' },
  { id: 'signup' },
]

function setStorage(key, value) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value)
  }
}

const handleUuid = () => {
  if (typeof window === 'undefined') {
    return {}
  }

  const stored_uuid = localStorage.getItem('onboarding_uuid') || null
  const stored_startTime = localStorage.getItem('onboarding_startTime') || null

  // How long should it take before an old onboarding session is no longer valid? (in millisecs)
  const STATE_TTL = 2 * 60 * 60 * 1000 // TODO Move to config, time before localStorage is stale

  if (
    stored_uuid !== null &&
    stored_startTime !== null &&
    Date.now() - stored_startTime < STATE_TTL
  ) {
    return {
      startTime: localStorage.getItem('onboarding_startTime'),
      uuid: localStorage.getItem('onboarding_uuid'),
      name: localStorage.getItem('onboarding_name') || '',
      gender: localStorage.getItem('onboarding_gender') || '',
      moreThanOne: localStorage.getItem('onboarding_moreThanOne') || '',
      born: localStorage.getItem('onboarding_birthdate') || '',
      anniversary: localStorage.getItem('onboarding_anniversary') || '',
      size: localStorage.getItem('onboarding_size') || '',
      zipCode: localStorage.getItem('onboarding_zipCode') || '',
      breed_0: localStorage.getItem('onboarding_breed_0') || '',
      breed_1: localStorage.getItem('onboarding_breed_1') || '',
      breed_2: localStorage.getItem('onboarding_breed_2') || '',
      breed_3: localStorage.getItem('onboarding_breed_3') || '',
      breedId_0: localStorage.getItem('onboarding_breedId_0') || '',
      breedId_1: localStorage.getItem('onboarding_breedId_1') || '',
      breedId_2: localStorage.getItem('onboarding_breedId_2') || '',
      breedId_3: localStorage.getItem('onboarding_breedId_3') || '',
      isUrbanOrRural:
        localStorage.getItem('onboarding_isUrbanOrRural') || 'Rural',
      firstDog: localStorage.getItem('onboarding_firstDog') || '',
    }
  }

  return freshUuid()
}

const freshUuid = async () => {
  // clears old onboarding keys
  Object.keys(localStorage)
    .filter((k) => {
      return k.startsWith('onboarding_')
    })
    .map((k) => localStorage.removeItem(k))

  // TODO FIND THINGS TO STORE...
  //    - i.e. referer, ip, local timezone
  const startData = {}
  startData['browser-userAgent'] = navigator.userAgent || ''
  startData['browser-language'] = navigator.language || ''

  const rt = await startOnboarding(startData)

  // console.log(rt)

  setStorage('onboarding_uuid', rt.uuid)
  setStorage('onboarding_startTime', new Date(rt.created_at).getTime())

  return {
    startTime: localStorage.getItem('onboarding_startTime'),
    uuid: localStorage.getItem('onboarding_uuid'),
    name: '',
    moreThanOne: '',
    born: '',
    anniversary: '',
    size: '',
    zipCode: '',
    breed_0: '',
    breed_1: '',
    breed_2: '',
    breed_3: '',
    breedId_0: '',
    breedId_1: '',
    breedId_2: '',
    breedId_3: '',
    isUrbanOrRural: 'Rural',
    firstDog: '',
  }
}

const Onboarding = () => {
  const localStorageData = handleUuid()

  if (isLoggedIn()) {
    typeof window !== 'undefined' && navigate('/app/home')
    return null
  }

  const [formData, setForm] = useForm(localStorageData)

  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  })

  const className = classNames(animateTransition)
  const props = { formData, setForm, navigation, className }

  switch (step.id) {
    case 'hello':
      return (
        <LayOnboarding>
          <Hello {...props} />
        </LayOnboarding>
      )
    case 'name':
      return (
        <LayOnboarding>
          <Name {...props} />
        </LayOnboarding>
      )
    case 'nameConfirmation':
      return (
        <LayOnboarding>
          <NameConfirmation {...props} />
        </LayOnboarding>
      )
    case 'birthDate':
      return (
        <LayOnboarding>
          <BirthDate {...props} />
        </LayOnboarding>
      )
    case 'gotchaversary':
      return (
        <LayOnboarding>
          <Gotchaversary {...props} />
        </LayOnboarding>
      )
    case 'size':
      return (
        <LayOnboarding>
          <Size {...props} />
        </LayOnboarding>
      )
    case 'stepZip':
      return (
        <LayOnboarding>
          <ZipCode {...props} />
        </LayOnboarding>
      )
    case 'breed':
      return (
        <LayOnboarding>
          <Breed {...props} />
        </LayOnboarding>
      )
    case 'breedConfirmation':
      return (
        <LayOnboarding>
          <BreedConfirmation {...props} />
        </LayOnboarding>
      )
    case 'firstDog':
      return (
        <LayOnboarding>
          <FirstDog {...props} />
        </LayOnboarding>
      )
    case 'buildingCustomHomepage':
      return (
        <LayOnboarding>
          <BuildingCustomHomepage {...props} />
        </LayOnboarding>
      )
    case 'signup':
      return (
        <LayOnboarding>
          <Signup {...props} />
        </LayOnboarding>
      )
  }

  return <h1>Multi step form</h1>
}

export default Onboarding
