import React, { useContext, useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { Auth } from 'aws-amplify'

import { navigate } from 'gatsby'

import Snackbar from '@material-ui/core/Snackbar'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import { useAlert, Alert } from '../../hooks/alert'
import { buildUserProfile, buildPetProfile } from '../../services/apiPayload'
import { signUp } from '../../services/auth'
import {
  createUser,
  createPet,
  completeOnboarding,
} from '../../services/zootopia'

import { AuthDispatchContext, LOG_IN } from '../../context/AuthContextProvider'

import obStyles from '../layout-onboarding.module.scss'

export const Signup = ({ className, formData, navigation }) => {
  const { name } = formData

  const recaptchaRef = React.createRef()

  const [passwordVisible, setPasswordVisible] = useState(false)

  const [alertOpen, alertMessage, openAlert, closeAlert] = useAlert()

  const handleTogglePasswordVisible = () => setPasswordVisible(!passwordVisible)
  const handleAlertClose = (event, reason) => closeAlert(reason)

  const dispatch = useContext(AuthDispatchContext)

  const config = {
    aws: {
      region: process.env.GATSBY_AWS_REGION,
      cognito: {
        userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOLS_ID,
        userPoolWebClientId:
          process.env.GATSBY_AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
      },
    },
    recaptcha: {
      siteKey: process.env.GATSBY_RECAPTCHA_SITE_KEY,
    },
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: object({
      firstName: string().required('First name is required'),
      lastName: string().required('Last name is required'),
      email: string().email().required('Email is required'),
      password: string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(
          /[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/,
          'Password must contain at least one special character'
        )
        .notOneOf([name], "Password must not be your pet's name"),
    }),
    onSubmit: async (values) => {
      try {
        const recaptchaToken = await recaptchaRef.current.executeAsync()

        values.validationData = [
          {
            Name: 'recaptchaToken',
            Value: recaptchaToken,
          },
        ]

        // Create the user's account
        const user = await signUp(values)

        if (user) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'virtualPageview',
            path: '/onboarding/step10/success',
            title: 'Onboarding - Success.',
          })
        }

        const userProfile = buildUserProfile(
          values,
          localStorage.getItem('onboarding_zipCode'),
          localStorage.getItem('onboarding_moreThanOne')
        )
        const petProfile = buildPetProfile()

        // Send the user and pet's details to the API
        const userResponse = await createUser(userProfile)
        const petResponse = await createPet(petProfile)

        const filteredLocalStorage = Object.keys(localStorage)
          .filter((key) => key.startsWith('onboarding_'))
          .reduce((obj, key) => {
            obj[key] = localStorage[key]
            return obj
          }, {})

        const onboardingResponse = await completeOnboarding(
          localStorage.getItem('onboarding_uuid'),
          petResponse.id,
          filteredLocalStorage
        )

        // clears localStorage
        Object.keys(localStorage)
          .filter((k) => {
            return k.startsWith('onboarding_')
          })
          .map((k) => localStorage.removeItem(k))

        await dispatch({ type: LOG_IN })

        navigate('/app/home')
      } catch (err) {
        openAlert(err.message)
      }
    },
  })

  Auth.configure({
    region: config.aws.region,
    userPoolId: config.aws.cognito.userPoolId,
    userPoolWebClientId: config.aws.cognito.userPoolWebClientId,
  })

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step09/createaccount',
      title: 'Onboarding - Please create an account to access it.',
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>{name}'s homepage is ready!</h1>
      <p className={obStyles.desc}>Please create an account to access it.</p>

      <form
        className={obStyles.onboardingForm}
        id="signup-form"
        onSubmit={formik.handleSubmit}
      >
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
          size="invisible"
        />

        <div className={obStyles.formBody}>
          <fieldset className={obStyles.col2}>
            <div>
              <label className={obStyles.label} htmlFor="firstName">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                className={obStyles.inputSignup}
                name="firstName"
                aria-required="true"
                aria-invalid={
                  formik.errors.firstName && formik.touched.firstName
                    ? 'true'
                    : null
                }
                placeholder="First Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className={obStyles.errorMsg}>
                  {formik.errors.firstName}
                </div>
              ) : null}
            </div>
            <div>
              <label className={obStyles.label} htmlFor="lastName">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                className={obStyles.inputSignup}
                name="lastName"
                aria-required="true"
                aria-invalid={
                  formik.errors.lastName && formik.touched.lastName
                    ? 'true'
                    : null
                }
                placeholder="Last Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className={obStyles.errorMsg}>
                  {formik.errors.lastName}
                </div>
              ) : null}
            </div>
          </fieldset>
          <label className={obStyles.label} htmlFor="email">
            Your Email
          </label>
          <input
            type="email"
            id="email"
            className={obStyles.inputSignup}
            name="email"
            aria-required="true"
            placeholder="Enter email"
            className={obStyles.wide}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={obStyles.errorMsg}>{formik.errors.email}</div>
          ) : null}
          <label className={obStyles.label} htmlFor="password">
            Create a Password
          </label>
          <div className={obStyles.inputWithControl}>
            <input
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              className={obStyles.inputSignup}
              name="password"
              aria-required="true"
              placeholder="Enter password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div
              className={obStyles.control}
              onClick={handleTogglePasswordVisible}
              onKeyPress={handleTogglePasswordVisible}
              role="button"
              tabIndex="0"
            >
              {passwordVisible ? 'Hide' : 'Show'}
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className={obStyles.errorMsg}>{formik.errors.password}</div>
            ) : null}
          </div>
          <div className={obStyles.validationCheck}>
            <ul>
              <li>8 characters minimum</li>
              <li>One number</li>
              <li>One special character</li>
            </ul>

            <ul>
              <li>One upper case character</li>
              <li>One lower case character</li>
              <li>Don’t use your dog’s name</li>
            </ul>
          </div>

          <div className={obStyles.legal}>
            By clicking “Sign Up”, you acknowledge that you have read the{' '}
            <a href="https://hifloof-dev.com/terms-of-service" target="_blank">
              Terms of Service Agreement
            </a>
            , understand it, and agree to be bound by it.
            <br/><br/>
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </div>
        </div>
        <div className={obStyles.navigation}>
          <div>
            <button
              type="button"
              className={obStyles.back}
              onClick={() => navigation.go(9)}
            >
              Back
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={obStyles.button}
              aria-disabled={formik.isSubmitting}
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={alertOpen}
        message={alertMessage}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}
