import React, { useState, useEffect } from 'react'

import { useFormik } from 'formik'
import { boolean, object, string } from 'yup'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import obStyles from '../layout-onboarding.module.scss'

export const Name = ({ className, formData, setForm, navigation }) => {
  const { name, moreThanOne, gender } = formData
  const [isActive, setActive] = useState(moreThanOne)

  const formik = useFormik({
    initialValues: {
      name,
      gender,
    },
    validationSchema: object({
      name: string().required('Dog name is required'),
      gender: string()
        .required('Please select a gender')
        .oneOf(['boy', 'girl']),
    }),
    onSubmit: (values) => {
      setStorage('onboarding_name', values.name)

      // TODO: We could also leverage Formik to save values to local storage, etc. once the form is valid and is
      //       being submitted
      // console.log(JSON.stringify(values)) // const { name } = values would work/could be used here
      navigation.next()

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'onboarding',
        multipledogs: moreThanOne,
      })
    },
  })

  // set local storage of checkbox to default state
  localStorage.setItem('onboarding_moreThanOne', moreThanOne)

  function setStorage(key, value) {
    localStorage.setItem(key, value)
  }

  function toggleCheckbox() {
    setActive(!isActive)
  }

  const handleChange = (e) => {
    setForm(e)

    formik.handleChange(e)
  }

  const handleGenderRadioGroupChange = (e) => {
    setForm(e)
    setStorage('onboarding_gender', e.target.value)

    formik.handleChange(e)
  }

  const handleBlur = (e) => {
    formik.handleBlur(e)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      formik.handleSubmit()
    }
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step02/dogsname',
      title: "Onboarding - What's your dog's name?",
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>What's your dog's name?</h1>

      <form className={obStyles.onboardingForm} onSubmit={formik.handleSubmit}>
        <div className={obStyles.formBody}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={handleGenderRadioGroupChange}
              className={obStyles.radiogroup}
              aria-required="true"
            >
              <FormControlLabel
                classes={{
                  label: obStyles.radioLabel,
                }}
                value="girl"
                control={<Radio />}
                label="Her name is"
                style={{ marginLeft: '-0.5625rem' }}
              />
              <FormControlLabel
                classes={{
                  label: obStyles.radioLabel,
                }}
                className={obStyles.radioButton}
                value="boy"
                control={<Radio />}
                label="His name is"
              />
            </RadioGroup>
            {formik.touched.gender && formik.errors.gender ? (
              <div className={obStyles.errorMsgOutside}>
                {formik.errors.gender}
              </div>
            ) : null}
          </FormControl>

          <div className={obStyles.name}>
            <label className={obStyles.label} htmlFor="dogName">
              Dog's name
            </label>
            <input
              id="dogName"
              type="text"
              className={obStyles.inputMedium}
              name="name"
              value={formik.values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              aria-required="true"
              aria-invalid={
                formik.errors.name && formik.touched.name ? 'true' : null
              }
            />
            {formik.touched.name && formik.errors.name ? (
              <div className={obStyles.errorMsg}>{formik.errors.name}</div>
            ) : null}
          </div>

          <div className={obStyles.moreThanOneDog}>
            <Checkbox
              id="c1"
              type="checkbox"
              name="moreThanOne"
              checked={isActive}
              onChange={setForm}
              onFocus={() =>
                setStorage(
                  'onboarding_moreThanOne',
                  moreThanOne === !moreThanOne
                )
              }
              onClick={() => toggleCheckbox()}
            />
            <label htmlFor="c1" className={obStyles.inline}>
              I have more than one dog
            </label>
            <div>
              <p className={isActive ? 'showNotice' : null}>
                Awesome — the more the merrier! We can only support one dog per
                parent for now, but we're working on the ability to add more.
                Once you sign up, we'll alert you to any updates.
              </p>
            </div>
          </div>
        </div>

        <div className={obStyles.navigation}>
          <div>
            <button
              className={obStyles.back}
              onClick={() => navigation.previous()}
            >
              Back
            </button>
          </div>
          {/*
          The validation states for disabling/enabling the Continue button are a bit funky - particularly since the button's
          disabled state is set via the disabled attribute so everything is inverted and more confusing - so I'm adding
          some notes...

          First things first. These are the dirty - true if the form's values not (deeply) equal to their initial values; i.e. if
          the form's values have changed - and isValid states for...

          - The first time the onboarding step/form is loaded and there's no pet name (Disable the Continue button):
          dirty: false
          isValid: true
          Value (i.e. formik.values.name): false
          - Onboarding process reload (Enable the Continue button since a pet name is loaded from local storage when the page
            is rendered):
          dirty: false
          isValid: true
          Value: true

          Since dirty is false and isValid is true on first load, the way to disable the Continue button at that point is
          'not dirty AND isValid' - !(formik.dirty && formik.isValid)
          (https://stackoverflow.com/questions/59443005/react-formik-form-validation-how-to-initially-have-submit-button-disabled)

          However... If the user re-starts the onboarding process and their pet's name is loaded from local storage, dirty and isValid
          are still false and true respectively. So the correct name is in the name field but the Continue button is disabled until
          the user changes it (which they don't want to do). So, the Continue button's disabled state also needs to be tied to
          the value in the field:
          
          Disable the Continue button if 'the name field is empty' AND 'not dirty AND isValid' - (!formik.values.name && !(formik.dirty && formik.isValid))

          TODO: Encapsulate this in a function
          */}
          <div>
            <button
              type="submit"
              className={obStyles.button}
              aria-disabled={
                // Note: Unlike text fields, which can be left empty and blurred (which allows the validation process to execute),
                //       radio buttons are either touched and have a value or they're not. So, instead of this...
                //
                // (!(formik.values.name && formik.values.gender) &&
                //
                // ...I removed formik.value.gender from the button's check. This enables the button when the user enters a name
                // and only validates the radio button's value when they click the button; if they haven't selected a gender, they'll
                // see an error message
                (!formik.values.name && !(formik.dirty && formik.isValid)) ||
                formik.isSubmitting
              }
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
