import React from 'react'

import obStyles from '../layout-onboarding.module.scss'

import { getBreed } from '../../services/zootopia'

export const BreedConfirmation = ({ className, formData, navigation }) => {
  const {
    breed_0,
    breed_1,
    breed_2,
    breed_3,
    breedId_0,
    breedId_1,
    breedId_2,
    breedId_3,
  } = formData

  let breedData = []
  let setBreedData = []
  ;[breedData[0], setBreedData[0]] = React.useState({})
  ;[breedData[1], setBreedData[1]] = React.useState({})
  ;[breedData[2], setBreedData[2]] = React.useState({})
  ;[breedData[3], setBreedData[3]] = React.useState({})

  const getBreedAndSetIdx = async (breedId, idx = 0) => {
    if (!breedId) {
      return
    }

    const responseBreedData = await getBreed(breedId)
    setBreedData[idx](responseBreedData)
  }

  React.useEffect(() => {
    getBreedAndSetIdx(breedId_0, 0)
    getBreedAndSetIdx(breedId_1, 1)
    getBreedAndSetIdx(breedId_2, 2)
    getBreedAndSetIdx(breedId_3, 3)
  }, [])

  setTimeout(() => {
    navigation.next()
  }, 2100)

  if (!breedId_0 || !breedData[0]) {
    return (
      <div className={className}>
        <h1 className={obStyles.heading}>Cool...we love all dogs!</h1>
        <div className={obStyles.desc}>
          From supermixes to rare breeds — they're all amazing.{' '}
        </div>
      </div>
    )
  }

  if (!breedId_1) {
    return (
      <div className={className}>
        <h1 className={obStyles.heading}>
          {breedData[0]['plural'] ? (
            <>Awww... We love {breedData[0]['plural']}</>
          ) : null}
        </h1>
      </div>
    )
  }

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>Cool...we love all dogs!</h1>
      <div className={obStyles.desc}>
        From supermixes to rare breeds — they're all amazing.{' '}
      </div>
    </div>
  )
}
