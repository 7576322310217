import React, { useEffect } from 'react'
import styled from 'styled-components'

import obStyles from '../layout-onboarding.module.scss'

const Heading = styled.h1`
  margin-top: 4rem;
  
  @media (min-width: 768px) {
    margin-top: 8rem;
  }
`

const StyledDesc = styled.p`
  font-size: 1.75rem;
  margin: 0 auto 3.25rem;
  max-width: 37.5rem;
`
const HelloPetParent = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    img {
      display: block;
      width: 20vw;
    }
  }
`
const HelloPet = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin-bottom: 2rem;
    position: absolute;
    bottom: 0;
    right: 0;

    img {
      display: block;
      width: 30vw;
    }
  }
`

export const Hello = ({ navigation, className }) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step01/start',
      title: 'Onboarding - Start',
    })
  })

  return (
    <div className={className}>
      <Heading className={obStyles.heading}>Hi there!</Heading>
      <StyledDesc className={obStyles.desc}>
        Let's learn a little bit about your dog so we can make the best game
        plan for you both.
      </StyledDesc>
      <div className={obStyles.navigationOneButton}>
        <div>
          <button className={obStyles.button} onClick={() => navigation.next()}>
            Add your dog
          </button>
        </div>
      </div>
      <HelloPetParent>
        <img
          src="/img/onboarding/hello_pet_parent.svg"
          alt="Illustration of a person opening a door."
        />
      </HelloPetParent>
      <HelloPet>
        <img
          src="/img/onboarding/hello_dog.svg"
          alt="Illustration of a dog happily running towards the person."
        />
      </HelloPet>
    </div>
  )
}
