import React from 'react'
import PropTypes from 'prop-types'

import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import styled from 'styled-components'

const StyledAutocomplete = styled(Autocomplete)`
  &.addAnother {
    .MuiInputBase-root {
      background-color: transparent;
    }

    label {
      color: #fff;
      font-size: 1rem;
      padding-bottom: 0.25rem;
      padding-right: 0;
      margin-left: -10px;
      border-bottom: 1px solid #fff;
    }

    &:not(.Mui-focused) {
      .MuiAutocomplete-endAdornment {
        display: none;
      }
      input:hover {
        cursor: pointer;
      }
    }

    &.Mui-focused {
      .MuiInputBase-root {
        margin-top: 2rem;
        padding: 0 !important;
      }
      .MuiAutocomplete-endAdornment {
        display: block;
      }

      input {
        background-color: #fff;
        padding: 1.125rem 38px 1rem 1rem !important;
      }
    }
  }
`

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: #fff;
    border-radius: 0;
    min-width: 19rem;
    max-width: 29.375rem;
    padding: 1.125rem 1rem !important;
  }

  .MuiAutocomplete-endAdornment svg {
    fill: #999 !important;
  }

  label {
    color: #999;
    font-family: 'GTAmerica';
    font-size: 1.125rem;
    margin: 0;
    padding-right: 1.3125rem;
  }

  input {
    font-size: 1.125rem;
    margin: 0;
    padding: 0 1.3125rem 0 0 !important;
  }
`

const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault()
    e.stopPropagation()
  }
}

const AutocompleteField = ({
  id,
  name,
  options,
  textFieldLabel,
  ...additionalAutocompleteProps
}) => (
  <StyledAutocomplete
    id={id}
    name={name}
    options={options}
    renderInput={(params) => (
      <StyledTextField
        label={textFieldLabel}
        variant="outlined"
        aria-labelledby="selectNote"
        {...params}
      />
    )}
    blurOnSelect
    clearOnBlur
    forcePopupIcon={false}
    onKeyDown={handleKeyDown}
    {...additionalAutocompleteProps}
  />
)

AutocompleteField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  textFieldLabel: PropTypes.string,
}

export default AutocompleteField
