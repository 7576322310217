import React, { useEffect } from 'react'

import { DatePickerField, parseDateString } from './DatePicker'

import { useFormik } from 'formik'
import { object, date } from 'yup'

import obStyles from '../layout-onboarding.module.scss'

export const Gotchaversary = ({ className, formData, setForm, navigation }) => {
  const { name, born, anniversary, gender } = formData

  const formik = useFormik({
    initialValues: {
      anniversary,
    },
    validationSchema: object({
      anniversary: date()
        .transform(parseDateString)
        .required('Gotchaversary is required')
        .min(born, `Gotchaversary cannot be before ${name}'s birthday`)
        .max(new Date(), 'Gotchaversary cannot be in the future')
        .typeError('Gotchaversary should be in mm/dd/yyyy format'),
    }),
    onSubmit: (values) => {
      setStorage('onboarding_anniversary', values.anniversary)
      calculateDays()
      navigation.next()
    },
  })

  // for Google Analytics
  function calculateDays() {
    let currentDate = new Date().getTime()
    let anniversaryDate = new Date(formik.values.anniversary).getTime()
    let gotchaDays = Math.floor(
      // convert milliseconds into actual number of days
      (currentDate - anniversaryDate) / (1000 * 60 * 60 * 24)
    )

    let gotchaDayGroup = ''

    if (gotchaDays <= 30) {
      gotchaDayGroup = '< 1 month'
    } else if (gotchaDays > 30 && gotchaDays < 91) {
      gotchaDayGroup = '1-3 months'
    } else if (gotchaDays >= 91 && gotchaDays < 181) {
      gotchaDayGroup = '3-6 months'
    } else if (gotchaDays >= 181 && gotchaDays < 271) {
      gotchaDayGroup = '6-9 months'
    } else if (gotchaDays >= 271 && gotchaDays < 366) {
      gotchaDayGroup = '9-12 months'
    } else {
      gotchaDayGroup = '> 12 months'
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'onboarding',
      gotchaDays: gotchaDays,
      gotchaDayGroup: gotchaDayGroup,
    })
  }

  function setStorage(key, value) {
    localStorage.setItem(key, value)
  }

  const handleChange = (_, date) => {
    formik.setFieldValue('anniversary', date, true)
    setForm({
      target: {
        name: 'anniversary',
        value: date,
      },
    })
  }

  const handleBlur = (date) => {
    formik.handleBlur({
      target: {
        name: 'anniversary',
        value: date,
      },
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()

      formik.handleSubmit()
    }
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step04/arrival',
      title: 'Onboarding - Date of Arrival at Home',
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>When did you bring {name} home?</h1>
      <p className={obStyles.desc}>
        {gender === 'girl' ? 'Her' : 'His'} gotchaversary deserves all the
        treats.
      </p>

      <form className={obStyles.onboardingForm} onSubmit={formik.handleSubmit}>
        <div className={obStyles.formBody}>
          <label className={obStyles.label} htmlFor="anniversary">
            Date
          </label>
          <DatePickerField
            id="date"
            name="anniversary"
            autoFocus={
              !formik.values.anniversary && !formik.touched.anniversary
            }
            error={Boolean(
              formik.errors.anniversary && formik.touched.anniversary
            )}
            helperText={formik.errors.anniversary}
            inputValue={formik.values.anniversary || 'mm/dd/yyyy'}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>

        <div className={obStyles.navigation}>
          <div>
            <button
              className={obStyles.back}
              onClick={() => navigation.previous()}
            >
              Back
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={obStyles.button}
              disabled={
                (!formik.values.anniversary &&
                  !(formik.dirty && formik.isValid)) ||
                formik.isSubmitting
              }
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
