import React from "react";

import obStyles from '../layout-onboarding.module.scss'

export const NameConfirmation = ({ className, formData, navigation }) => {
  const { name, gender } = formData;

  setTimeout(() => { 
      navigation.next()
    }, 2500);
  

  return (
    <div className={className}>
        <h1 className={obStyles.heading}>{name}! What a great name.</h1>
        <p className={obStyles.desc}>Who's a good {gender}? {name} is!</p>
    </div>
  );
};