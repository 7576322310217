import React, { useEffect } from 'react'
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp'

import obStyles from '../layout-onboarding.module.scss'

export const Size = ({ className, formData, navigation }) => {
  const { name, size } = formData

  function setStorage(key, value) {
    formData['size'] = value
    localStorage.setItem(key, value)
    window.dataLayer.push({
      event: 'onboarding',
      dogsize: value,
    })
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step06/size',
      title: 'Onboarding - Size',
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>What size is {name}?</h1>

      <div className={obStyles.gallery}>
        <section
          onMouseDown={() => setStorage('onboarding_size', 'toy')}
          onClick={() => navigation.next()}
          onKeyPress={() => navigation.next()}
          role="button"
          tabIndex="0"
          className={obStyles.toy}
        >
          <div className={obStyles.illustration}>
            <img
              src="/img/onboarding/size_toy.png"
              alt="Illustration of tiny dog"
            />
          </div>
          <div>
            <h4>Tiny</h4>
            <p>0 to 10 lbs</p>
          </div>
          <i className="icon next">
            <NavigateNextSharpIcon />
          </i>
        </section>
        <section
          onMouseDown={() => setStorage('onboarding_size', 'small')}
          onClick={() => navigation.next()}
          onKeyPress={() => navigation.next()}
          role="button"
          tabIndex="0"
          className={obStyles.small}
        >
          <div className={obStyles.illustration}>
            <img
              src="/img/onboarding/size_small.png"
              alt="Illustration of small dog"
            />
          </div>
          <div>
            <h4>Small</h4>
            <p>11 to 30 lbs</p>
          </div>
          <i className="icon next">
            <NavigateNextSharpIcon />
          </i>
        </section>
        <section
          onMouseDown={() => setStorage('onboarding_size', 'medium')}
          onClick={() => navigation.next()}
          onKeyPress={() => navigation.next()}
          role="button"
          tabIndex="0"
          className={obStyles.medium}
        >
          <div className={obStyles.illustration}>
            <img
              src="/img/onboarding/size_medium.png"
              alt="Illustration of medium-sized dog"
            />
          </div>
          <div>
            <h4>Medium</h4>
            <p>31 to 50 lbs</p>
          </div>
          <i className="icon next">
            <NavigateNextSharpIcon />
          </i>
        </section>
        <section
          onMouseDown={() => setStorage('onboarding_size', 'large')}
          onClick={() => navigation.next()}
          onKeyPress={() => navigation.next()}
          role="button"
          tabIndex="0"
          className={obStyles.large}
        >
          <div className={obStyles.illustration}>
            <img
              src="/img/onboarding/size_large.png"
              alt="Illustration of large dog"
            />
          </div>
          <div>
            <h4>Large</h4>
            <p>51 to 90 lbs</p>
          </div>
          <i className="icon next">
            <NavigateNextSharpIcon />
          </i>
        </section>
        <section
          onMouseDown={() => setStorage('onboarding_size', 'giant')}
          onClick={() => navigation.next()}
          onKeyPress={() => navigation.next()}
          role="button"
          tabIndex="0"
          className={obStyles.giant}
        >
          <div className={obStyles.illustration}>
            <img
              src="/img/onboarding/size_giant.png"
              alt="Illustration of giant dog"
            />
          </div>
          <div>
            <h4>Giant</h4>
            <p>91 lbs or more</p>
          </div>
          <i className="icon next">
            <NavigateNextSharpIcon />
          </i>
        </section>
      </div>

      <div className={obStyles.navigation}>
        <div>
          <button className={obStyles.back} onClick={() => navigation.go(5)}>
            Back
          </button>
        </div>

        <div>
          {size && (
            <button
              className={obStyles.button}
              onClick={() => navigation.next()}
            >
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
