import moment from 'moment'

const getVal = (key) => localStorage.getItem(key)

const breedString = () => {
  let result = ''
  let slots = [0, 1, 2, 3]

  slots.forEach((slot) => {
    const breedName = getVal(`onboarding_breed_${slot}`)

    if (breedName) {
      if (slot > 0) {
        result = `${result}/`
      }

      result = `${result}${breedName}`
    }
  })

  return result
}

const upperFirstOnly = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

const year = (dateStr) =>
  parseInt(dateStr.match(/^(?<year>[0-9]{4}).*$/).groups.year)

export const buildUserProfile = (
  { firstName: first_name, lastName: last_name, email },
  zipCode,
  multipleDogOwner
) => ({
  first_name,
  last_name,
  email,
  postal_zip: zipCode,
  multiple_dog_owner: multipleDogOwner === 'true' ? 'true' : 'false',
})

export const buildPetProfile = () => {
  const birthDate = moment.utc(getVal('onboarding_birthdate')).format('YYYY-MM-DD')
  const anniversaryDate = moment.utc(getVal('onboarding_anniversary')).format('YYYY-MM-DD')

  return {
    name: getVal('onboarding_name'),
    animal_type: 'Dog',
    breed: breedString(),
    birth_year: birthDate ? year(birthDate) : null,
    birth_date: birthDate,
    acquisition_date: anniversaryDate, 
    // TODO: Standardize these values so a conversion step isn't necessary?
    gender: getVal('onboarding_gender').toLowerCase() === 'boy' ? 'M' : 'F',
    size: upperFirstOnly(getVal('onboarding_size')),
    wisdom_breed_id_1: getVal('onboarding_breedId_0') || '',
    wisdom_breed_id_2: getVal('onboarding_breedId_1') || '',
    wisdom_breed_id_3: getVal('onboarding_breedId_2') || '',
    wisdom_breed_id_4: getVal('onboarding_breedId_3') || '',
    // TODO: Use a boolean?
    is_first_pet:
      getVal('onboarding_firstDog').toLowerCase() === 'yes' ? 'true' : 'false',
  }
}
