import React, { useEffect } from 'react'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import classNames from 'classnames'
import obStyles from '../layout-onboarding.module.scss'

import { isUrbanOrRural } from '../../services/zootopia'

export const ZipCode = ({ className, formData, setForm, navigation }) => {
  const { name, zipCode } = formData

  const formik = useFormik({
    initialValues: {
      zipCode,
    },
    validationSchema: object({
      zipCode: string()
        .required('Your ZIP code is required')
        .matches(
          /^[0-9]{5}(?:-[0-9]{4})?$/,
          'Your ZIP code must be 5 or 9 digits long'
        ),
    }),
    onSubmit: async (values) => {
      setStorage('onboarding_zipCode', values.zipCode)

      const rt = await isUrbanOrRural(values.zipCode)
      localStorage.setItem('onboarding_isUrbanOrRural', rt)

      window.dataLayer.push({
        event: 'onboarding',
        zipcodeType: rt,
      })

      navigation.next()
    },
  })

  const setStorage = async (key, value) => {
    localStorage.setItem(key, value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      formik.handleSubmit()
    }
  }

  const handleChange = (e) => {
    setForm(e)

    formik.handleChange(e)
  }

  const handleBlur = (e) => {
    formik.handleBlur(e)
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step07/zipcode',
      title: 'Onboarding - Zip Code',
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>What's your zip code?</h1>
      <p className={obStyles.desc}>
        We'd love to personalize you and {name}'s experience with some nearby
        options
      </p>
      <form className={obStyles.onboardingForm} onSubmit={formik.handleSubmit}>
        <div className={obStyles.formBody}>
          <label className={obStyles.label} htmlFor="zip">
            Zip code
          </label>

          <input
            type="text"
            id="zip"
            className={obStyles.input}
            placeholder="Enter your zip code"
            label="Zip Code"
            name="zipCode"
            aria-required="true"
            aria-invalid={
              formik.errors.zipCode && formik.touched.zipCode ? 'true' : null
            }
            value={formik.values.zipCode}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
          {formik.touched.zipCode && formik.errors.zipCode ? (
            <div>{formik.errors.zipCode}</div>
          ) : null}
        </div>

        <div className={obStyles.navigation}>
          <div>
            <button
              className={obStyles.back}
              onClick={() => navigation.previous()}
            >
              Back
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={obStyles.button}
              aria-disabled={
                (!formik.values.zipCode && !(formik.dirty && formik.isValid)) ||
                formik.isSubmitting
              }
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
