import React, { useEffect } from 'react'

import obStyles from '../layout-onboarding.module.scss'

export const FirstDog = ({ className, formData, setForm, navigation }) => {
  const { name, firstDog } = formData

  function setStorage(key, value) {
    formData['firstDog'] = value
    localStorage.setItem(key, value)
    window.dataLayer.push({
      event: 'onboarding',
      firstdog: value,
    })
  }

  const activeStyle = { border: '3px solid black' }
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step08/firstdog',
      title: 'Onboarding - Is this your first dog?',
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>Is this your first dog?</h1>

      <div className={obStyles.firstDogResponse}>
        <button
          style={firstDog === 'yes' ? activeStyle : {}}
          value="Yes"
          onMouseDown={() => setStorage('onboarding_firstDog', 'yes')}
          onClick={() => navigation.next()}
          className={obStyles.lrgButton}
        >
          Yes
        </button>
        <button
          style={firstDog === 'no' ? activeStyle : {}}
          value="No"
          onMouseDown={() => setStorage('onboarding_firstDog', 'no')}
          onClick={() => navigation.next()}
          className={obStyles.lrgButton}
        >
          No
        </button>
      </div>

      <div className={obStyles.navigation}>
        <div>
          <button
            className={obStyles.back}
            onClick={() => navigation.previous()}
          >
            Back
          </button>
        </div>
        {firstDog && (
          <div>
            <button
              className={obStyles.button}
              onClick={() => navigation.next()}
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
