import React from 'react'
import Helmet from 'react-helmet'
import Logo from '../components/logo'
import obStyles from './layout-onboarding.module.scss'
import './layout-onboarding.scss'

export default function LayOnboarding({ children }) {
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'onboarding',
        }}
      />
      <div className={obStyles.wrapper}>
        <Logo className={obStyles.logo} />
        {children}
      </div>
    </>
  )
}
