import React, { useEffect } from 'react'

import { DatePickerField, parseDateString } from './DatePicker'

import { useFormik } from 'formik'
import { object, date } from 'yup'

import obStyles from '../layout-onboarding.module.scss'

export const BirthDate = ({ className, formData, setForm, navigation }) => {
  const { name, born } = formData

  const formik = useFormik({
    initialValues: {
      born,
    },
    validationSchema: object({
      born: date()
        .transform(parseDateString)
        .required(`${name}'s birthday is required`)
        .max(new Date(), `${name}'s birthday cannot be in the future`)
        .typeError('Birthday should be in mm/dd/yyyy format'),
    }),
    onSubmit: (values) => {
      setStorage('onboarding_birthdate', values.born)
      navigation.next()
    },
  })

  function setStorage(key, value) {
    localStorage.setItem(key, value)
  }

  const handleChange = (_, date) => {
    formik.setFieldValue('born', date, true)
    setForm({
      target: {
        name: 'born',
        value: date,
      },
    })
  }

  const handleBlur = (date) => {
    formik.handleBlur({
      target: {
        name: 'born',
        value: date,
      },
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()

      formik.handleSubmit()
    }
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'virtualPageview',
      path: '/onboarding/step03/dob',
      title: 'Onboarding - Date of Birth',
    })
  }, [])

  return (
    <div className={className}>
      <h1 className={obStyles.heading}>When was {name} born?</h1>
      <p className={obStyles.desc}>
        A best guess works, if you don't know the exact date{' '}
      </p>

      <form className={obStyles.onboardingForm} onSubmit={formik.handleSubmit}>
        <div className={obStyles.formBody}>
          <label className={obStyles.label} htmlFor="born">
            Date
          </label>
          <DatePickerField
            id="date"
            name="born"
            autoFocus={!formik.values.born && !formik.touched.born}
            error={Boolean(formik.errors.born && formik.touched.born)}
            helperText={formik.errors.born}
            inputValue={formik.values.born || 'mm/dd/yyyy'}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            required
          />
        </div>

        <div className={obStyles.navigation}>
          <div>
            <button className={obStyles.back} onClick={() => navigation.go(1)}>
              Back
            </button>
          </div>
          <div>
            <button
              type="submit"
              className={obStyles.button}
              aria-disabled={
                (!formik.values.born && !(formik.dirty && formik.isValid)) ||
                formik.isSubmitting
              }
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
